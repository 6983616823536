import Analytics from "./Analytics/Analytics";
import State from "./Analytics/State";
import PubSub from "./Analytics/PubSub";
import contextsHandler from "./SnowPlow/ContextsHandler";
import SnowPlowLoginOpenSubscriber from "./SnowPlow/Subscribers/LoginOpenSubscriber";
import SnowPlowLoginCloseSubscriber from "./SnowPlow/Subscribers/LoginCloseSubscriber";
import SnowPlowGhostAccountTerminatedSubscriber from "./SnowPlow/Subscribers/GhostAccountTerminatedSubscriber";
import SnowPlowScrolledToBeginOfBlockSubscriber from "./SnowPlow/Subscribers/ScrolledToBeginOfBlockSubscriber";
import SnowPlowScrolledToBlockDepthSubscriber from "./SnowPlow/Subscribers/ScrolledToBlockDepthSubscriber";
import SnowPlowScrolledToBlockItemSubscriber from "./SnowPlow/Subscribers/ScrolledToBlockItemSubscriber";
import SnowPlowTimeSpentSubscriber from "./SnowPlow/Subscribers/TimeSpentSubscriber";
import SnowPlowAuthReadySubscriber from "./SnowPlow/Subscribers/AuthReadySubscriber";
import SnowPlowPostViewedSubscriber from "./SnowPlow/Subscribers/PostViewedSubscriber";
import SnowPlowPageViewHeartbeatSubscriber from "./SnowPlow/Subscribers/PageViewHeartbeatSubscriber";
import SnowPlowSearchRequestedSubscriber from "./SnowPlow/Subscribers/SearchRequestedSubscriber";
import SnowPlowReaderConsentChangedSubscriber from "./SnowPlow/Subscribers/ReaderConsentChangedSubscriber";
import SnowPlowNewsletterSubscriptionCompletedSubscriber from "./SnowPlow/Subscribers/NewsletterSubscriptionCompletedSubscriber";
import SnowPlowLinkClickedSubscriber from "./SnowPlow/Subscribers/LinkClickedSubscriber";
import SnowPlowButtonClickedSubscriber from "./SnowPlow/Subscribers/ButtonClickedSubscriber";
import SnowPlowShareButtonClickedSubscriber from "./SnowPlow/Subscribers/ShareButtonClickedSubscriber";
import SnowPlowVideoReadySubscriber from "./SnowPlow/Subscribers/VideoReadySubscriber";
import SnowPlowVideoMarkReachedSubscriber from "./SnowPlow/Subscribers/VideoMarkReachedSubscriber";
import SnowPlowVideoEnteredFullscreenSubscriber from "./SnowPlow/Subscribers/VideoEnteredFullscreenSubscriber";
import SnowPlowVideoPausedSubscriber from "./SnowPlow/Subscribers/VideoPausedSubscriber";
import SnowPlowVideoStartedSubscriber from "./SnowPlow/Subscribers/VideoStartedSubscriber";
import SnowPlowVideoUnpausedSubscriber from "./SnowPlow/Subscribers/VideoUnpausedSubscriber";
import SnowPlowAudioReadySubscriber from "./SnowPlow/Subscribers/AudioReadySubscriber";
import SnowPlowAudioMarkReachedSubscriber from "./SnowPlow/Subscribers/AudioMarkReachedSubscriber";
import SnowPlowAudioPausedSubscriber from "./SnowPlow/Subscribers/AudioPausedSubscriber";
import SnowPlowAudioStartedSubscriber from "./SnowPlow/Subscribers/AudioStartedSubscriber";
import SnowPlowAudioUnpausedSubscriber from "./SnowPlow/Subscribers/AudioUnpausedSubscriber";
import DatalayerLoginCloseSubscriber from "./DataLayer/Subscribers/LoginCloseSubscriber";
import DatalayerLoginOpenSubscriber from "./DataLayer/Subscribers/LoginOpenSubscriber";
import DatalayerGhostAccountTerminatedSubscriber from "./DataLayer/Subscribers/GhostAccountTerminatedSubscriber";
import DatalayerScrolledToBeginOfBlockSubscriber from "./DataLayer/Subscribers/ScrolledToBeginOfBlockSubscriber";
import DatalayerScrolledToBlockItemSubscriber from "./DataLayer/Subscribers/ScrolledToBlockItemSubscriber";
import DatalayerTimeSpentSubscriber from "./DataLayer/Subscribers/TimeSpentSubscriber";
import DatalayerAuthReadySubscriber from "./DataLayer/Subscribers/AuthReadySubscriber";
import DatalayerPageViewHeartbeatSubscriber from "./DataLayer/Subscribers/PageViewHeartbeatSubscriber";
import DatalayerPostViewedSubscriber from "./DataLayer/Subscribers/PostViewedSubscriber";
import DatalayerSearchRequestedSubscriber from "./DataLayer/Subscribers/SearchRequestedSubscriber";
import DatalayerReaderConsentChangedSubscriber from "./DataLayer/Subscribers/ReaderConsentChangedSubscriber";
import DatalayerNewsletterSubscriptionCompletedSubscriber from "./DataLayer/Subscribers/NewsletterSubscriptionCompletedSubscriber";
import DatalayerNewAccountLoginCloseSubscriber from "./DataLayer/Subscribers/NewAccountLoginCloseSubscriber";
import DatalayerLinkClickedSubscriber from "./DataLayer/Subscribers/LinkClickedSubscriber";
import DatalayerShareButtonClickedSubscriber from "./DataLayer/Subscribers/ShareButtonClickedSubscriber";
import DatalayerButtonClickedSubscriber from "./DataLayer/Subscribers/ButtonClickedSubscriber";
import DatalayerScrolledToBlockDepthSubscriber from "./DataLayer/Subscribers/ScrolledToBlockDepthSubscriber";
import DatalayerVideoReadySubscriber from "./DataLayer/Subscribers/VideoReadySubscriber";
import DatalayerVideoMarkReachedSubscriber from "./DataLayer/Subscribers/VideoMarkReachedSubscriber";
import DatalayerVideoEnteredFullscreenSubscriber from "./DataLayer/Subscribers/VideoEnteredFullscreenSubscriber";
import DatalayerVideoPausedSubscriber from "./DataLayer/Subscribers/VideoPausedSubscriber";
import DatalayerVideoStartedSubscriber from "./DataLayer/Subscribers/VideoStartedSubscriber";
import DatalayerVideoUnpausedSubscriber from "./DataLayer/Subscribers/VideoUnpausedSubscriber";
import DatalayerAudioReadySubscriber from "./DataLayer/Subscribers/AudioReadySubscriber";
import DatalayerAudioMarkReachedSubscriber from "./DataLayer/Subscribers/AudioMarkReachedSubscriber";
import DatalayerAudioPausedSubscriber from "./DataLayer/Subscribers/AudioPausedSubscriber";
import DatalayerAudioStartedSubscriber from "./DataLayer/Subscribers/AudioStartedSubscriber";
import DatalayerAudioUnpausedSubscriber from "./DataLayer/Subscribers/AudioUnpausedSubscriber";
import { newTracker } from "@snowplow/browser-tracker";
export class BootstrapAnalytics {
    constructor(brazeEnabled, datalayer, snowplowCollectorUrl, snowplowPostPath) {
        const state = new State();
        const configuration = {
            appId: "news-website",
            platform: "web",
            contexts: {
                webPage: true,
            },
            eventMethod: "beacon",
        };
        if (snowplowPostPath) {
            configuration.postPath = snowplowPostPath;
        }
        newTracker("nuglif-plus-one", snowplowCollectorUrl, configuration);
        this.analytics = new Analytics(state, new PubSub());
        this.datalayer = datalayer;
        this.snowplowContextsHandler = new contextsHandler(state, brazeEnabled);
        this._initializeSnowplowSubscriber();
        this._initializeDatalayerSubscriber();
    }
    _initializeSnowplowSubscriber() {
        new SnowPlowScrolledToBeginOfBlockSubscriber(this.analytics.pubSub, this.snowplowContextsHandler);
        new SnowPlowScrolledToBlockDepthSubscriber(this.analytics.pubSub, this.snowplowContextsHandler);
        new SnowPlowScrolledToBlockItemSubscriber(this.analytics.pubSub, this.snowplowContextsHandler);
        new SnowPlowAuthReadySubscriber(this.analytics.pubSub, this.snowplowContextsHandler);
        new SnowPlowNewsletterSubscriptionCompletedSubscriber(this.analytics.pubSub, this.snowplowContextsHandler);
        new SnowPlowButtonClickedSubscriber(this.analytics.pubSub, this.snowplowContextsHandler);
        new SnowPlowLinkClickedSubscriber(this.analytics.pubSub, this.snowplowContextsHandler);
        new SnowPlowReaderConsentChangedSubscriber(this.analytics.pubSub, this.snowplowContextsHandler);
        new SnowPlowShareButtonClickedSubscriber(this.analytics.pubSub, this.snowplowContextsHandler);
        new SnowPlowSearchRequestedSubscriber(this.analytics.pubSub, this.snowplowContextsHandler);
        new SnowPlowPostViewedSubscriber(this.analytics.pubSub, this.snowplowContextsHandler);
        new SnowPlowPageViewHeartbeatSubscriber(this.analytics.pubSub, this.snowplowContextsHandler);
        new SnowPlowLoginOpenSubscriber(this.analytics.pubSub, this.snowplowContextsHandler);
        new SnowPlowLoginCloseSubscriber(this.analytics.pubSub, this.snowplowContextsHandler);
        new SnowPlowGhostAccountTerminatedSubscriber(this.analytics.pubSub, this.snowplowContextsHandler);
        new SnowPlowTimeSpentSubscriber(this.analytics.pubSub, this.snowplowContextsHandler);
        new SnowPlowVideoReadySubscriber(this.analytics.pubSub, this.snowplowContextsHandler);
        new SnowPlowVideoEnteredFullscreenSubscriber(this.analytics.pubSub, this.snowplowContextsHandler);
        new SnowPlowVideoMarkReachedSubscriber(this.analytics.pubSub, this.snowplowContextsHandler);
        new SnowPlowVideoPausedSubscriber(this.analytics.pubSub, this.snowplowContextsHandler);
        new SnowPlowVideoStartedSubscriber(this.analytics.pubSub, this.snowplowContextsHandler);
        new SnowPlowVideoUnpausedSubscriber(this.analytics.pubSub, this.snowplowContextsHandler);
        new SnowPlowAudioReadySubscriber(this.analytics.pubSub, this.snowplowContextsHandler);
        new SnowPlowAudioMarkReachedSubscriber(this.analytics.pubSub, this.snowplowContextsHandler);
        new SnowPlowAudioPausedSubscriber(this.analytics.pubSub, this.snowplowContextsHandler);
        new SnowPlowAudioStartedSubscriber(this.analytics.pubSub, this.snowplowContextsHandler);
        new SnowPlowAudioUnpausedSubscriber(this.analytics.pubSub, this.snowplowContextsHandler);
    }
    _initializeDatalayerSubscriber() {
        new DatalayerTimeSpentSubscriber(this.analytics.pubSub, this.datalayer);
        new DatalayerGhostAccountTerminatedSubscriber(this.analytics.pubSub, this.datalayer);
        new DatalayerLoginOpenSubscriber(this.analytics.pubSub, this.datalayer);
        new DatalayerLoginCloseSubscriber(this.analytics.pubSub, this.datalayer);
        new DatalayerPageViewHeartbeatSubscriber(this.analytics.pubSub, this.datalayer);
        new DatalayerButtonClickedSubscriber(this.analytics.pubSub, this.datalayer);
        new DatalayerPostViewedSubscriber(this.analytics.pubSub, this.datalayer);
        new DatalayerSearchRequestedSubscriber(this.analytics.pubSub, this.datalayer);
        new DatalayerShareButtonClickedSubscriber(this.analytics.pubSub, this.datalayer);
        new DatalayerScrolledToBlockDepthSubscriber(this.analytics.pubSub, this.datalayer);
        new DatalayerScrolledToBeginOfBlockSubscriber(this.analytics.pubSub, this.datalayer);
        new DatalayerScrolledToBlockItemSubscriber(this.analytics.pubSub, this.datalayer);
        new DatalayerNewsletterSubscriptionCompletedSubscriber(this.analytics.pubSub, this.datalayer);
        new DatalayerAuthReadySubscriber(this.analytics.pubSub, this.datalayer);
        new DatalayerLinkClickedSubscriber(this.analytics.pubSub, this.datalayer);
        new DatalayerReaderConsentChangedSubscriber(this.analytics.pubSub, this.datalayer);
        new DatalayerNewAccountLoginCloseSubscriber(this.analytics.pubSub, this.datalayer);
        new DatalayerVideoReadySubscriber(this.analytics.pubSub, this.datalayer);
        new DatalayerVideoEnteredFullscreenSubscriber(this.analytics.pubSub, this.datalayer);
        new DatalayerVideoMarkReachedSubscriber(this.analytics.pubSub, this.datalayer);
        new DatalayerVideoPausedSubscriber(this.analytics.pubSub, this.datalayer);
        new DatalayerVideoStartedSubscriber(this.analytics.pubSub, this.datalayer);
        new DatalayerVideoUnpausedSubscriber(this.analytics.pubSub, this.datalayer);
        new DatalayerAudioReadySubscriber(this.analytics.pubSub, this.datalayer);
        new DatalayerAudioMarkReachedSubscriber(this.analytics.pubSub, this.datalayer);
        new DatalayerAudioPausedSubscriber(this.analytics.pubSub, this.datalayer);
        new DatalayerAudioStartedSubscriber(this.analytics.pubSub, this.datalayer);
        new DatalayerAudioUnpausedSubscriber(this.analytics.pubSub, this.datalayer);
    }
}
